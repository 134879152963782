import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("./config");

// Customizable Area Start
import { debounce } from "lodash";
import { IMenuItem } from "./Types";
import { RouteComponentProps } from "react-router";
import { eventEmitter } from "../../../../framework/src/utils/EventEmitter";
import {
  setStorageData,
  getStorageData,
  removeStorageData,
} from "framework/src/Utilities";
import { getBannerURL } from "../../../ss-cms-common-components/src/Utilities/Utilities";
import { initGTM } from "../../../../components/src/GoogleTagManager";
const { queryParameters } = configJSON;
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../components/src/Firebase";
import * as configcat from "configcat-js";
import {
  configCatClient,
  getDesignTemplate,
  themeFeatureWithoutWebsiteBuilder,
} from "../../../../components/src/FeatureFlag";
const baseURL = require("../../../../framework/src/config.js").baseURL;

export type Props = RouteComponentProps & {
  classes: any;
  navigation: {
    navigate: (to: string, params: object) => void;
    getParam: (param: string) => string;
    goBack: () => void;
  };
  id: string;
};

interface S {
  isHeaderVisible: boolean;
  lastScrollY: number;
  countValue: number;
  isLoading: boolean;
  isSearchLoading: boolean;
  anchorEl: any;
  isMobile: boolean;
  isMenuOpen: boolean;
  searchValue: string;
  menuItems: IMenuItem[];
  selectedMenuItem: any | null;
  moreClicked: boolean;
  isSearchOpen: boolean;
  isSearchPage: boolean;
  searchItems: any[];
  historySearchItems: any[];
  moreButtonAnchor: null | Element;
  gtmScript: string;
  storeName: string;
  openLogin: boolean;
  signUpModal: boolean;
  magicLink: boolean;
  verifyMailModal: boolean;
  email: string;
  password: string;
  userToken: string;
  modalType: "profile" | "login" | "magic";
  anchorElement: null | HTMLElement;
  designDetails: any;
  customerServices: any;
  isLogoutModalOpen: boolean;
  isAccActiveMsgOpen: boolean;
  showProfile: boolean;
  seoData: {
    metaTitle?: string;
    metaDescription?: string;
    metaKeywords?: string;
  };
  build_card: string;
  pagePath: string;
  isSearchEnabled: boolean;
  countryName: string;
  adminEmail: string;
}

interface SeoDataResponse {
  errors?: string;
  id: number;
  keywords: string[] | null;
  meta_description: string | null;
  meta_title: string | null;
}

interface SS {
  id: any;
}
// Customizable Area End

export default class AppHeaderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  logoImgRef: React.RefObject<HTMLImageElement>;
  logoContainerRef: React.RefObject<HTMLDivElement>;
  apiGetMenuItemsCallId: string = "";
  apiGetCategoriesCallId: string = "";
  apiGetSuggestedSearchCallId: string = "";
  getCartItemCountCallId: string = "";
  getStoreDetailsCallId: string = "";
  getCustomerServiceApiEndpointCallId: string = "";
  passwordVerificationId: string = "";
  getSeoDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.logoImgRef = React.createRef();
    this.logoContainerRef = React.createRef();

    this.state = {
      isHeaderVisible: true,
      lastScrollY: 0,
      countValue: 0,
      isLoading: true,
      isSearchLoading: false,
      anchorEl: null,
      isMobile: window.innerWidth < 768,
      isMenuOpen: false,
      searchValue: "",
      menuItems: [],
      selectedMenuItem: null,
      moreClicked: false,
      isSearchOpen: false,
      isSearchPage: false,
      searchItems: [],
      historySearchItems: JSON.parse(
        localStorage.getItem("searchHistory") || "[]"
      ),
      moreButtonAnchor: null,
      gtmScript: "",
      storeName: "",
      openLogin: false,
      signUpModal: false,
      magicLink: false,
      verifyMailModal: false,
      email: "",
      password: "",
      userToken: "",
      modalType: "login",
      anchorElement: null,
      designDetails: {
        header: {
          storeLogo: "",
          headerItems: [{ itemUrl: "", categoryName: "" }],
          storeTextLogoDetails: { logoFont: "", logoColor: "" },
          chosenHeaderTheme: "Elevate",
        },
        footer: {
          showContactDetails: true,
          showSocialLinks: true,
          showAppLinks: true,
          copyRightText: "© 2021 All rights reserved",
        },
        themesAndFont: { selectedTheme: "Classic Elegance" },
      },
      customerServices: {
        customerPhone: "",
        customerEmail: "",
        customerAddress: "",
        storeName: "",
      },
      isLogoutModalOpen: false,
      isAccActiveMsgOpen: false,
      showProfile: false,
      seoData: {
        metaTitle: "",
        metaDescription: "",
        metaKeywords: "",
      },
      build_card: "",
      pagePath: "",
      isSearchEnabled: false,
      countryName: "",
      adminEmail: "",
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleGetMenuItemsResponse(apiRequestCallId, responseJson);
      this.handleGetCategoriesResponse(apiRequestCallId, responseJson);
      this.handleGetSuggestedSearchResponse(apiRequestCallId, responseJson);
      this.handleGetCartItemCountResponse(apiRequestCallId, responseJson);
      this.handleGetStoreDetailsResponse(apiRequestCallId, responseJson);
      this.handleCustomerServiceApiEndpointResponse(
        apiRequestCallId,
        responseJson
      );
      this.handleSeoDataResponse(apiRequestCallId, responseJson);
    }
  }

  handleCustomerServiceApiEndpointResponse = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.getCustomerServiceApiEndpointCallId) {
      if (responseJson) {
        const attr = responseJson.data.attributes;
        const { address_line_1, address_line_2, country_name, zipcode } = attr;
        const gtmScript = responseJson.data.attributes.gtm_script ?? "";
        initGTM(gtmScript);
        logEvent(analytics, "page_view", {
          buildCardId: this.state.build_card,
          page_path: this.state.pagePath,
        });
        const storeName = attr?.name;
        this.setState(
          { storeName, countryName: attr.country_name, adminEmail: attr.email },
          () => this.featureFlag()
        );
        const customerPhone = attr?.phone_number;
        const customerEmail = attr?.customer_support_email;
        let customerAddress = address_line_1 ? `${address_line_1}` : "";
        customerAddress += address_line_2 ? `, ${address_line_2}` : "";
        customerAddress += country_name ? `, ${country_name}` : "";
        customerAddress += zipcode ? `, ${zipcode}` : "";
        const emailAndBuildCard = {
          email: attr.email,
          build_card: attr.build_card,
        };
        setStorageData("eventData", JSON.stringify(emailAndBuildCard));

        if (
          localStorage.getItem("customerServices") ===
            JSON.stringify({
              storeName,
              customerPhone,
              customerEmail,
              customerAddress,
            }) ||
          localStorage.getItem("currency_type") === attr.currency_symbol
        ) {
          localStorage.setItem(
            "customerServices",
            JSON.stringify({
              storeName,
              customerPhone,
              customerEmail,
              customerAddress,
            })
          );
          localStorage.setItem("currency_type", attr.currency_symbol);
          this.setState({
            customerServices: {
              storeName,
              customerPhone,
              customerEmail,
              customerAddress,
            },
          });
        } else {
          localStorage.setItem(
            "customerServices",
            JSON.stringify({
              storeName,
              customerPhone,
              customerEmail,
              customerAddress,
            })
          );
          localStorage.setItem("currency_type", attr.currency_symbol);
          this.setState({
            customerServices: {
              storeName,
              customerPhone,
              customerEmail,
              customerAddress,
            },
          });
        }
        this.getStoreDetails();
      }
    }
  };

  handleGetMenuItemsResponse = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.apiGetMenuItemsCallId) {
      if (responseJson) {
        const menuItems = responseJson.map((item: any, itemIndex: number) => {
          const title = item.data.attributes.items.title;
          const url = item.data.attributes.items.url;

          if (!item.data.attributes.menu_items) {
            return {
              id: `item-${itemIndex}`,
              title,
              url,
            };
          }

          const menuItems = item.data.attributes.menu_items.map(
            (subItem: any, subItemIndex: number) => {
              const subItemTitle = subItem.attributes.title;
              const subItemUrl = subItem.attributes.url;
              return {
                id: `subItem-${subItemIndex}`,
                title: subItemTitle,
                url: subItemUrl,
              };
            }
          );

          return { id: `item-${itemIndex}`, title, url, menuItems };
        });

        this.setState({ menuItems });
      }
    }
  };

  handleGetCategoriesResponse = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.apiGetCategoriesCallId) {
      if (responseJson) {
        const menuItems = responseJson.data.map((item: any) => {
          const categorySlug = item.attributes.slug;
          const title = item.attributes.name;
          const url = `/catalogue/${categorySlug}`;

          if (item.attributes.sub_categories.length === 0) {
            return { slug: categorySlug, categoryName: title, itemUrl: url };
          }

          const menuItems = item.attributes.sub_categories.map(
            (subItem: any) => {
              const subItemSlug = subItem.slug;
              const subItemTitle = subItem.name;
              const subItemUrl = `/catalogue/${categorySlug}/${subItemSlug}`;
              return {
                slug: subItemSlug,
                itemName: subItemTitle,
                itemUrl: subItemUrl,
              };
            }
          );

          return {
            slug: categorySlug,
            categoryName: title,
            itemUrl: "",
            categoryItems: menuItems,
            webItemUrl: url,
          };
        });

        this.setState(
          { menuItems: [...this.state.menuItems, ...menuItems] },
          this.initializeCategories
        );
      }
    }
  };

  handleGetSuggestedSearchResponse = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.apiGetSuggestedSearchCallId) {
      this.setState({ isSearchLoading: false, searchItems: [] });

      if (responseJson) {
        if (responseJson.catalogues.data.length > 0) {
          const catalogues = responseJson.catalogues.data.map((item: any) => {
            const productSlug = item.attributes.slug;
            const categorySlug = item.attributes.category.slug;
            const subCategorySlug = item.attributes.selected_sub_category?.slug;
            let slug = `${categorySlug}/${productSlug}`;
            if (subCategorySlug) {
              slug = `${categorySlug}/${subCategorySlug}/${productSlug}`;
            }
            const productUrl =
              item.attributes.variant_id &&
              item.attributes.catalogue_variants?.length > 0
                ? `/product/${slug}/${item.attributes.variant_id}`
                : `/product/${slug}`;
            return {
              id: item.id,
              name: item.attributes.name,
              url: productUrl,
              type: "catalogue",
              image: item.attributes.images
                ? item.attributes.images[0].url
                : "",
            };
          });
          this.setState({
            searchItems: [...this.state.searchItems, ...catalogues],
          });
        }

        if (responseJson.categories.data.length > 0) {
          const categories = responseJson.categories.data.map((item: any) => {
            return {
              slug: item.attributes.slug,
              name: item.attributes.name,
              url: `/catalogue/${item.attributes.slug}`,
              type: "category",
              image: item.attributes.image ? item.attributes.image.url : "",
            };
          });
          this.setState({
            searchItems: [...this.state.searchItems, ...categories],
          });
        }
        if (responseJson.sub_categories.data.length > 0) {
          const subCategories = responseJson.sub_categories.data.map(
            (item: any) => {
              const categorySlug = this.state.menuItems.find((menuItem: any) =>
                menuItem.categoryItems?.find(
                  (subItem: any) => subItem.slug === item.attributes.slug
                )
              )?.slug;
              return {
                slug: item.attributes.slug,
                name: item.attributes.name,
                url: `/catalogue/${categorySlug}/${item.attributes.slug}`,
                type: "sub_category",
              };
            }
          );
          this.setState({
            searchItems: [...this.state.searchItems, ...subCategories],
          });
        }

        if (
          responseJson.catalogues.data.length === 0 &&
          responseJson.categories.data.length === 0 &&
          responseJson.sub_categories.data.length === 0
        ) {
          this.setState({ searchItems: [] });
        }
      }
    }
  };

  handleGetCartItemCountResponse = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.getCartItemCountCallId) {
      this.handleCartItemCountRespense(responseJson);
    }
  };

  setToCssRoot = function (vars: { [key: string]: string }) {
    for (const key in vars) {
      document.documentElement.style.setProperty(key, vars[key]);
    }
  };

  handleGetStoreDetailsResponse = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    if (apiRequestCallId === this.getStoreDetailsCallId) {
      const isProperJson = (str: string) => {
        try {
          JSON.parse(str);
        } catch (e) {
          return false;
        }
        return true;
      };

      if (responseJson) {
        const gtmScript = responseJson.data.attributes.gtm_script ?? "";
        this.setState({ gtmScript });
        let themesAndFont =
          responseJson.data.attributes.themes_and_font.data.attributes;
        
        let header = responseJson.data.attributes.header.data.attributes;

        if (!themesAndFont?.design_template) {
          themesAndFont = {
            ...themesAndFont,
            available_themes: [
              {
                id: 1,
                name: "Classic Elegance",
                is_default: true,
              },
              {
                id: 2,
                name: "Modern Marvel",
                is_default: false,
              },
            ],
            design_template: {
              id: 1,
              name: "Classic Elegance",
            },
          };
        }

       
        if (!header?.section_template) {
          header = {
            ...header,
            section_template: {
              id: 2,
              name: getDesignTemplate(themesAndFont?.design_template?.name)
                ?.header,
            },
          };
        } else {
          if (themeFeatureWithoutWebsiteBuilder) {
            header.section_template.name = getDesignTemplate(
              themesAndFont?.design_template?.name
            )?.header;
          }
        }

        let footer = responseJson.data.attributes.footer.data.attributes;
        let sections = responseJson.data.attributes.sections.data.map(
          (section: any) => {
            return section.attributes;
          }
        );
        let metadata = isProperJson(responseJson.data.attributes.metadata)
          ? JSON.parse(responseJson.data.attributes.metadata)
          : {};

        themesAndFont = {
          primaryColor: themesAndFont.primary_colour,
          secondaryColor: themesAndFont.secondary_colour,
          accentColor: `${themesAndFont.primary_colour}1A`,
          headerTextFontFamily: themesAndFont.header_text,
          bodyTextFontFamily: themesAndFont.body_text,
          selectedTheme: themesAndFont?.design_template?.name ?? "Classic Elegance",
        };

        this.setToCssRoot({
          "--design-primary-color": themesAndFont.primaryColor,
          "--design-light-primary-color": `${themesAndFont.primaryColor}52`,
          "--design-primary-opacity-color": `${themesAndFont.primaryColor}99`,
          "--design-secondary-color": themesAndFont.secondaryColor,
          "--design-accent-color": themesAndFont.accentColor,
          "--design-header-text-font": themesAndFont.headerTextFontFamily,
          "--design-body-text-font": themesAndFont.bodyTextFontFamily,
        });

        const getMenuItems = () => {
          let countOfItems = 0;
          return metadata.headerNavigationData.menuData.map((menu: any) => {
            if (countOfItems > 3) {
              return;
            }
            if (menu.selectedOption !== "Select") {
              countOfItems += 1;
              return {
                categoryName: menu.selectedOption,
                itemUrl: `/catalogue/${
                  this.state.menuItems.find(
                    (category) => category.categoryName === menu.selectedOption
                  )?.slug
                }`,
              };
            }
          });
        };

        header = {
          storeLogo: header.logo_url,
          storeTextLogoDetails: {
            logoColor: header.logo_colour,
            logoFont: header.logo_font,
          },
          customFavicon: header.custom_favicon,
          favIcon: header.favicon_url,
          headerItems: getMenuItems(),
          chosenHeaderTheme: header.section_template.name,
        };

        footer = {
          showContactDetails: footer.is_contact,
          showSocialLinks: footer.is_social_media,
          facebookUrl: footer.facebook_url,
          instagramUrl: footer.instagram_url,
          twitterUrl: footer.x_url,
          linkedInUrl: footer.linkedin_url,
          youtubeUrl: footer.youtube_url,
          showAppLinks: footer.is_app_link,
          appStoreUrl: footer.appstore_url,
          playStoreUrl: footer.playstore_url,
          copyRightText: footer.copyright,
        };

        sections = sections.map((section: any, index: number) => {
          if (section.section_type === "category") {
            const categories = JSON.parse(section.raw_metadata).categories.map(
              (category: any) => {
                if (category?.slug) {
                  return {
                    name: category.category_name,
                    url: `/catalogue/${category.slug}`,
                    image: category.category_image,
                  };
                }
              }
            );

            return {
              sectionType: "category",
              sectionTitle: section.section_name,
              categories,
              sectionTemplate: section?.section_template?.name
            };
          } else if (section.section_type === "product") {
            if (!isProperJson(section.metadata)) {
              return {
                sectionType: "product",
                sectionTitle: section.section_name,
                products: [],
                sectionTemplate: section?.section_template?.name
              };
            }
            const products = JSON.parse(section.metadata).products.map(
              (product: any) => {
                if (product?.productAttrs && product?.productAttrs?.id) {
                  return {
                    defaultVariantId: product.productAttrs.default_variant_id,
                    description: product.productAttrs.product_description,
                    id: product.productAttrs.id,
                    slug: product.productAttrs.slug,
                    name: product.productAttrs.product_name,
                    url: `/${product.productAttrs.slug}`,
                    imageUrl: product.productAttrs.product_image,
                    price: product.productAttrs.old_rate.toString(),
                    discountedPrice: product.productAttrs.new_rate,
                    discount: product.productAttrs.discount,
                  };
                }
              }
            );

            return {
              sectionType: "product",
              sectionTitle: section.section_name,
              products,
              sectionTemplate: section?.section_template?.name,
            };
          } else if (section.section_type === "banner") {
            const getTextDetails = (metadata: any) => {
              return {
                fontColor: metadata.font_colour,
                fontSize: metadata.font_size,
                textContent: metadata.header_text,
                textPosition: metadata.text_position,
                textAlignment: metadata.text_alignment,
              };
            };

            const mobileBannerMetadata = JSON.parse(
              isProperJson(section.mobile_banner_metadata)
                ? section.mobile_banner_metadata
                : "{}"
            );
            const desktopBannerMetadata = JSON.parse(
              isProperJson(section.desktop_banner_metadata)
                ? section.desktop_banner_metadata
                : "{}"
            );

            const banner = {
              mobileBannerUrl: getBannerURL(
                section.mobile_banner_url || "",
                section.mobile_banner_variants
              ),
              mobileBannerTextDetails: getTextDetails(mobileBannerMetadata),
              desktopBannerUrl: getBannerURL(
                section.desktop_banner_url || "",
                section.desktop_banner_variants
              ),
              desktopBannerTextDetails: getTextDetails(desktopBannerMetadata),
              directionUrl: section.banner_url,
            };

            return {
              firstHeaderBanner: index === 0,
              sectionType: "banner",
              banner,
            };
          }
        });

        this.setFavicon(header, this.state.storeName);

        if (
          localStorage.getItem("designDetails") ===
          JSON.stringify({ themesAndFont, header, footer, sections })
        ) {
          localStorage.setItem(
            "designDetails",
            JSON.stringify({ themesAndFont, header, footer, sections })
          );
          this.setState({
            designDetails: { themesAndFont, header, footer, sections },
          });
        } else {
          localStorage.setItem(
            "designDetails",
            JSON.stringify({ themesAndFont, header, footer, sections })
          );
          this.setState({
            designDetails: { themesAndFont, header, footer, sections },
          });
        }
        eventEmitter.dispatch("updateDesignDetails", {
          designDetails: { themesAndFont, header, footer, sections },
          customerServices: {
            storeName: this.state.storeName,
            customerPhone: this.state.customerServices.customerPhone,
            customerEmail: this.state.customerServices.customerEmail,
            customerAddress: this.state.customerServices.customerAddress,
          },
        });
      }
    }
  };

  setFavicon = (
    headerData: {
      storeTextLogoDetails: { logoColor: string; logoFont: string };
      customFavicon: boolean;
      favIcon: string;
    },
    storeName: string = "S"
  ) => {
    const text = storeName;

    const textColor = headerData.storeTextLogoDetails.logoColor;
    const faviconElement = document.querySelector(
      '[rel="shortcut icon"]'
    ) as HTMLAnchorElement;

    let imgElement = document.createElement("img");
    imgElement.src = faviconElement.href;

    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");
    canvas.width = 128;
    canvas.height = 128;

    imgElement.onload = () => {
      this.applyFavicon(canvas, context, imgElement, faviconElement, text, {
        logo_font: headerData.storeTextLogoDetails.logoFont,
        textColor,
        custom_favicon: headerData.customFavicon,
        favicon_url: headerData.favIcon,
      });
    };
  };

  applyFavicon = (
    canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D | null,
    imgElement: HTMLImageElement,
    faviconElement: HTMLAnchorElement,
    text: string,
    headerData: {
      logo_font: string;
      textColor: string;
      custom_favicon: boolean;
      favicon_url: string;
    }
  ) => {
    const bgColor = "#ffffff";
    if (context) {
      // Draw Original Favicon as Background
      context.drawImage(imgElement, 0, 0, 128, 128);

      // Draw Notification Circle
      context.beginPath();
      context.rect(0, 0, canvas.width, canvas.height);

      context.fillStyle = bgColor;
      context.fill();

      // Draw Notification Number
      context.font = `bold 110px "${headerData.logo_font}", sans-serif`;
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillStyle = headerData.textColor;

      let letter = text.substring(0, 1);
      context.fillText(letter, canvas.width / 2, 76);
      // Replace favicon
      faviconElement.href = headerData.custom_favicon
        ? headerData.favicon_url
        : canvas.toDataURL("image/webp", 0.9);
    }
  };

  handleMoreButtonOpen = (event: React.MouseEvent) => {
    this.setState({ moreButtonAnchor: event.currentTarget });
  };

  handleMoreButtonClose = () => {
    this.setState({ moreButtonAnchor: null });
  };

  handleCartItemCountRespense = (responseJson: any) => {
    if (responseJson?.data) {
      let count = responseJson.data.attributes.cart_items_count;
      this.setState({ countValue: count });
    }
  };

  handleSearchBtnClicked = () => {
    if (this.state.isSearchOpen) {
      this.setState(
        { isSearchOpen: false, searchValue: "", searchItems: [] },
        () =>
          document
            .getElementById("AppHeader__SearchContainer")
            ?.getElementsByTagName("input")[0]
            .blur()
      );
    } else {
      // this.handleClose();
      this.handleMoreButtonClose();
      this.setState({ isSearchOpen: true }, () =>
        document
          .getElementById("AppHeader__SearchContainer")
          ?.getElementsByTagName("input")[0]
          .focus()
      );
    }
  };

  handleCrossClickedSearch = () => {
    this.setState({ isSearchOpen: false, searchValue: "", searchItems: [] });
  };

  handleMoreClicked = () => {
    this.setState({ moreClicked: true });
  };

  handleClick = (event: any, menuItem: any) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedMenuItem: menuItem,
    });
  };

  handleClose = () => {
    if (window.scrollY > 100) {
      if (window.scrollY < this.state.lastScrollY) {
        this.setState({ isHeaderVisible: true });
      } else {
        this.setState({ isHeaderVisible: false });
      }
    } else {
      this.setState({ isHeaderVisible: true });
    }
    this.setState({
      anchorEl: null,
      moreClicked: false,
      lastScrollY: window.scrollY,
    });
  };

  handleMenuOpen = (isSearchPage: boolean) => {
    this.setState({ isMenuOpen: true, selectedMenuItem: null, isSearchPage });
  };

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false });
  };

  handleSearchVisibility = (action: "blur" | "focus") => {
    if (action === "blur") {
      setTimeout(
        () =>
          this.setState({
            isSearchOpen: false,
            searchValue: "",
            searchItems: [],
          }),
        200
      );
    } else {
      this.setState({ isSearchOpen: true });
    }
  };

  handleSearchKeyDown = (key: string) => {
    const { searchValue, historySearchItems } = this.state;

    if (key === "Enter" && searchValue) {
      if (!historySearchItems.includes(searchValue)) {
        localStorage.setItem(
          "searchHistory",
          JSON.stringify([searchValue, ...historySearchItems])
        );
      }
      this.handleRedirect(`/catalogue?search=${searchValue}`);
    }
  };

  debouncedSearch = debounce(
    () =>
      this.state.searchValue
        ? this.getSuggestedSearch()
        : this.setState({ isSearchLoading: false, searchItems: [] }),
    500
  );

  setSearchValue = (value: string) => {
    this.setState(
      { isSearchLoading: true, searchValue: value },
      this.debouncedSearch
    );
  };

  handleSearchChange = (event: any) => {
    this.setSearchValue(event.target.value);
  };

  handleRedirect = (url: string) => {
    const filteringFor: "category" | "sub_category" | undefined =
      url.split("/").length > 3
        ? "sub_category"
        : url.split("/").length > 2
        ? "category"
        : undefined;
    let filterSlug = url.split("/")[2];
    if (url.split("/")[1] === "product") {
      this.props.history.push(url);
    } else if (filteringFor === "category") {
      this.props.history.push(url, {
        category: this.state.menuItems.find(
          (item: any) => item.slug === filterSlug
        )?.categoryName,
      });
      localStorage.setItem(
        "category",
        this.state.menuItems.find((item: any) => item.slug === filterSlug)
          ?.categoryName || ""
      );
      localStorage.removeItem("subCategory");
    } else if (filteringFor === "sub_category") {
      filterSlug = url.split("/")[3];
      const subCategory = this.state.menuItems
        .find((item: any) =>
          item.categoryItems?.find(
            (subItem: any) => subItem.slug === filterSlug
          )
        )
        ?.categoryItems!.find(
          (subItem: any) => subItem.slug === filterSlug
        )?.itemName;
      const category = this.state.menuItems.find((item: any) =>
        item.categoryItems?.find((subItem: any) => subItem.slug === filterSlug)
      )?.categoryName;
      this.props.history.push(url, { category, subCategory });
      localStorage.setItem("category", category || "");
      localStorage.setItem("subCategory", subCategory || "");
    } else {
      this.props.history.push(url);
    }
    window.location.reload();
  };

  handleResize = () => {
    if (window.innerWidth < 768) {
      this.setState({ isMobile: true });
    } else {
      this.logoImgRef.current?.classList.remove("loaded");
      this.logoContainerRef.current?.classList.remove("portrait");
      this.logoImgRef.current?.classList.remove("portrait");
      this.setState({ isMobile: false, isMenuOpen: false });
    }
  };

  forgotPwEmailClicked = () => {
    const url = window.location.href;
    const isActivationUrl = url.includes("account_activation=success&token=");
    if (url.includes("setnewpassword?type=forgot_passowrd&token=")) {
      setStorageData("loginToken", url.split("token=")[1]);
      this.setState(
        { openLogin: true, modalType: "login", signUpModal: false },
        () => {
          eventEmitter.dispatch("forgotPwEmailClicked", true);
          window.history.pushState({}, document.title, window.location.origin);
        }
      );
    } else if (isActivationUrl || url.includes("?type=login&token=")) {
      isActivationUrl && this.setState({ isAccActiveMsgOpen: true });
      setStorageData("loginToken", url.split("token=")[1]);
      this.handleLoginSuccess(url.split("token=")[1]);
      window.history.pushState({}, document.title, window.location.origin);
    }
  };

  handleCloseAccActiveMsg = () => {
    this.setState({ isAccActiveMsgOpen: false });
  };

  async componentDidMount() {
    const adminUsre = await getStorageData("eventData");
    if (adminUsre) {
      const adminData = JSON.parse(adminUsre);
      this.setState({ build_card: adminData.build_card });
      this.setState({ pagePath: window.location.href });
    }
    const designDetailsCached = JSON.parse(
      localStorage.getItem("designDetails") || "{}"
    )?.themesAndFont;
    if (designDetailsCached) {
      this.setToCssRoot({
        "--design-primary-color": designDetailsCached.primaryColor,
        "--design-primary-opacity-color": `${designDetailsCached.primaryColor}99`,
        "--design-secondary-color": designDetailsCached.secondaryColor,
        "--design-accent-color": designDetailsCached.accentColor,
        "--design-header-text-font": designDetailsCached.headerTextFontFamily,
        "--design-body-text-font": designDetailsCached.bodyTextFontFamily,
      });
      this.setState((prevState) => ({
        designDetails: {
          ...prevState.designDetails,
          themesAndFont: designDetailsCached,
        },
      }));
    }
    eventEmitter.subscribe(
      "updateDesignDetails",
      ({ designDetails, customerServices }: any) => {
        this.setState({ designDetails, customerServices, isLoading: false });
      }
    );
    eventEmitter.subscribe("badgeValue", (event: any) => {
      this.setState({ countValue: event });
    });
    eventEmitter.subscribe("showSignUp", (event: any) => {
      this.setState({ signUpModal: event });
    });
    const id = await localStorage.getItem("cartId");
    if (id) {
      this.getCartItemCount(id);
    }
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleClose);
    this.getCategories();
    this.getCustomerServiceApiEndpoint();
    this.promoCodeHandler();
    this.handleProfile();
    this.getSeoData();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    const profileModal = localStorage.getItem("profileModal");
    if (
      profileModal &&
      JSON.parse(profileModal) == "mobileProfileView" &&
      !this.state.showProfile
    ) {
      this.setState({ showProfile: true });
      this.handleMenuOpen(false);
      this.handleMobileLogin();
    }
    if (prevState.isMobile !== this.state.isMobile) {
      if (this.state.isMobile) {
        this.setState({ menuItems: [] }, this.getCategories);
      } else {
        this.setState({ menuItems: [] }, () => {
          this.getMenuItems();
          this.getCategories();
        });
      }
    }

    if (
      this.logoImgRef.current &&
      !this.logoImgRef.current.classList.contains("loaded") &&
      this.state.isMobile
    ) {
      this.logoImgRef.current.onload = () => {
        this.logoImgRef.current?.classList.add("loaded");

        const logoImg = this.logoImgRef.current;
        const aspectRatio = logoImg!.naturalWidth / logoImg!.naturalHeight;
        if (aspectRatio < 1) {
          this.logoContainerRef.current?.classList.add("portrait");
          this.logoImgRef.current?.classList.add("portrait");
          document.documentElement.style.setProperty(
            "--mobile-header-height",
            "130px"
          );
        } else {
          document.documentElement.style.setProperty(
            "--mobile-header-height",
            "64px"
          );
        }
      };
    }
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getMenuItems = () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetMenuItemsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCategories = () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCategoriesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSuggestedSearch = () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSuggestedSearchCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.suggestedSearchEndpoint + this.state.searchValue
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  initializeCategories = () => {
    const url = new URL(window.location.href);
    const currentCategoryName =
      this.state.menuItems.find(
        (item: any) => url.pathname.split("/")[2] === item.slug
      )?.categoryName || "";
    const currentSubCategoryName =
      this.state.menuItems
        .find((item: any) => url.pathname.split("/")[2] === item.slug)
        ?.categoryItems?.find(
          (subItem: any) => url.pathname.split("/")[3] === subItem.slug
        )?.itemName || "";
    localStorage.setItem("category", currentCategoryName || "");
    localStorage.setItem("subCategory", currentSubCategoryName || "");
  };

  getCartItemCount = (cartId: any) => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCartItemCountCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cartItemCountEndpoint}/${cartId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStoreDetails = () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStoreDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStoreDetailsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCustomerServiceApiEndpoint = () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomerServiceApiEndpointCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCustomerServiceApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  promoCodeHandler = () => {
    const params = new URLSearchParams(this.props.location.search);
    const promoCode = params.get("promo_code");
    if (promoCode && promoCode.length > 0) {
      sessionStorage.setItem("promo_code", promoCode);
    }
  };

  handleLogin = (event: React.MouseEvent<HTMLDivElement>) => {
    if (this.state.userToken) {
      this.setState({ anchorElement: event.currentTarget });
      return;
    }
    this.setState({ openLogin: true, signUpModal: false, modalType: "login" });
  };

  handleMenuProfileClose = () => {
    this.setState({ anchorElement: null });
  };

  handleLoginClose = () => {
    removeStorageData("priorScreen");
    eventEmitter.dispatch("loginModalClosed", true);
    this.setState((previous) => ({
      openLogin: false,
      signUpModal: false,
      modalType: previous.userToken ? "profile" : "login",
    }));
  };

  handleLoginSuccess = (token: string) => {
    this.setState({
      openLogin: false,
      userToken: token,
      signUpModal: false,
      modalType: "profile",
    });
    const currentPath = window.location.pathname;
    const pathParts = currentPath.split("/");
    const lastPart = pathParts[pathParts.length - 1];
    if (lastPart === "shopping-cart") {
      window.location.reload();
    }
  };

  navigateToHome = () => {
    window.location.href = `${window.location.origin}/`;
    this.handleLoginClose();
  };

  handleProfile = async () => {
    const userToken = await getStorageData("loginToken");
    this.setState(
      { userToken, modalType: userToken ? "profile" : "login" },
      this.forgotPwEmailClicked
    );
  };

  handleSignUp = () => {
    this.setState({ signUpModal: true, openLogin: false });
  };

  handleCloseSignUp = () => {
    this.setState({ signUpModal: false, openLogin: false, modalType: "login" });
  };

  handleMagicLink = (email: string) => {
    this.setState((prev) => ({
      magicLink: !prev.magicLink,
      openLogin: prev.openLogin,
      signUpModal: prev.signUpModal,
      modalType: "magic",
      email,
    }));
  };
  handleVerifyMail = () => {
    this.setState({
      verifyMailModal: !this.state.verifyMailModal,
      signUpModal: false,
    });
  };
  handleMobileLogin = () => {
    this.setState((previous) => ({
      isMenuOpen: false,
      signUpModal: false,
      openLogin: true,
      modalType: previous.userToken ? "profile" : "login",
    }));
  };

  generateRunEngineRequestMessage = (endPoint: string, method: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    return requestMessage;
  };

  handleLogout = () => {
    localStorage.removeItem("loginToken");
    localStorage.removeItem("mobileUserId");
    localStorage.removeItem("shippingAddress");
    localStorage.removeItem("user_saved_shipping_address");
    localStorage.removeItem("cartId");
    window.location.href = `${window.location.origin}/`;
  };

  handleLogoutModalOpen = () => {
    this.setState({
      isLogoutModalOpen: true,
    });
  };

  handleLogoutModalClose = () => {
    this.setState({
      isLogoutModalOpen: false,
    });
  };

  getMobileMenuCatList = () => {
    const headerItems = this.state.designDetails.header.headerItems;
    const menuItems = this.state.menuItems;

    const headerCategories = headerItems.map(
      (headerItem: { categoryName: string }) => {
        return menuItems.find(
          (menuItem: IMenuItem) =>
            menuItem.categoryName === headerItem?.categoryName
        );
      }
    );

    const allCategories = menuItems.filter(
      (menu: IMenuItem) =>
        !headerItems
          .map(
            (headerItem: { categoryName: string }) => headerItem?.categoryName
          )
          .includes(menu.categoryName)
    );

    return (
      this.state.selectedMenuItem?.categoryItems ?? [
        ...headerCategories,
        ...allCategories,
      ]
    );
  };

  getSeoData = () => {
    const pathname = new URL(window.location.href).pathname;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSeoDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSeoDetailsApiEndpoint}${pathname}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSeoDataResponse = (
    apiRequestCallId: string,
    responseJson: SeoDataResponse
  ) => {
    if (apiRequestCallId === this.getSeoDataCallId) {
      if (!responseJson?.errors) {
        this.setState({
          seoData: {
            metaTitle: responseJson?.meta_title || "",
            metaDescription: responseJson?.meta_description || "",
            metaKeywords: responseJson?.keywords?.join(", ") || "",
          },
        });
      }
    }
  };

  featureFlag = async () => {
    let identifier = this.state.build_card + "" + this.state.adminEmail;

    const userObject = new configcat.User(
      identifier,
      this.state.adminEmail,
      this.state.countryName,
      {
        buildCardId: this.state.build_card,
        Platform: "web",
        URL: baseURL,
      }
    );

    configCatClient
      .getValueAsync("searchfeatureflag", false, userObject)
      .then((value) => {
        this.setState({ isSearchEnabled: value });
      });
  };
  // Customizable Area End
}
